<template>
  <!-- 共通select用 -->
  <div>
    <b-form-group v-bind:description="description">
      <label v-bind:for="id">
        <b-badge v-if="required" variant="caccent">必須</b-badge>
        {{ label }}
      </label>
      <b-form-select
        v-bind:id="id"
        v-bind:required="required"
        v-bind:value="value"
        v-bind:options="options"
        v-bind:placeholder="placeholder"
        v-bind:disabled="disabled"
        v-bind:value-field="valueField"
        v-bind:text-field="textField"
        v-on:change="updateValue"
      ></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    description: { type: String, required: false },
    label: { type: String, required: true },
    id: { type: String, required: true },
    required: { type: Boolean, default: false },
    placeholder: { type: String, required: false },
    value: { required: false, default: null },
    options: { type: Array, required: true },
    valueField: { type: String, required: false },
    textField: { type: String, required: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<template>
  <!-- 共通input用 -->
  <div>
    <b-form-group v-bind:description="description">
      <label v-bind:for="id">
        <b-badge v-if="required" variant="caccent">必須</b-badge>
        {{ label }}
      </label>
      <b-form-input
        v-bind:id="id"
        v-bind:type="type"
        v-bind:pattern="pattern"
        v-bind:max="max"
        v-bind:title="title"
        v-bind:required="required"
        v-bind:value="value"
        v-bind:placeholder="placeholder"
        v-bind:disabled="disabled"
        v-bind:readonly="readonly"
        v-bind:class="textClass"
        v-on:input="inputValue"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { checkEmptyString } from '@/mixins/utility'
export default {
  mixins: [checkEmptyString],
  props: {
    description: { type: String, required: false },
    label: { type: String, required: true },
    id: { type: String, required: true },
    type: { type: String, default: 'text' },
    pattern: { type: String, required: false },
    max: { type: String, required: false },
    title: { type: String, required: false },
    required: { type: Boolean, default: false },
    placeholder: { type: String, required: false },
    value: { required: false, default: null },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    textClass: { type: String, required: false },
  },
  methods: {
    inputValue(event) {
      this.$emit('input', this.checkEmptyString(event))
    },
  },
}
</script>

<template>
  <div class="account-opening">
    <h2 class="text-cmain text-center">MYPLR口座を新規開設します</h2>
    <b-form v-on:submit.prevent="toConfirm()">
      <!-- 個人情報の入力 -->
      <b-card no-body class="mb-5 py-3">
        <b-card-body>
          <b-card-title title-tag="h3" class="text-cmain">
            個人情報の入力
          </b-card-title>
          <b-form-row>
            <b-col cols="6">
              <base-input
                v-model="personalInfo.surname"
                label="姓"
                id="surname"
                v-bind:required="true"
              >
              </base-input>
            </b-col>
            <b-col cols="6">
              <base-input
                v-model="personalInfo.firstname"
                label="名"
                id="firstname"
                v-bind:required="true"
              >
              </base-input>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <base-input
                v-model="personalInfo.surnameKana"
                label="姓よみがな"
                id="surname-kana"
                v-bind:required="true"
              >
              </base-input>
            </b-col>
            <b-col cols="6">
              <base-input
                v-model="personalInfo.firstnameKana"
                label="名よみがな"
                id="firstname-kana"
                v-bind:required="true"
              >
              </base-input>
            </b-col>
          </b-form-row>
          <base-input
            v-model="personalInfo.dateOfBirth"
            label="生年月日（西暦）"
            id="date-of-birth"
            type="date"
            max="9999-12-31"
            v-bind:required="true"
          >
          </base-input>
          <b-form-group>
            <legend>
              <b-badge variant="caccent">必須</b-badge>
              性別
            </legend>
            <b-form-radio-group
              id="gender"
              v-model="personalInfo.gender"
              v-bind:options="constants.gender"
              name="gender"
              class="box-large"
              required
            ></b-form-radio-group>
          </b-form-group>
          <base-input
            v-model="personalInfo.address1"
            description="現在お住まいの住所の都道府県を入力してください。"
            label="住所1（現住所：都道府県）"
            placeholder="例）大阪府"
            id="address1"
            v-bind:required="true"
          >
          </base-input>
          <base-input
            v-model="personalInfo.address2"
            description="現在お住まいの住所の市区町村を入力してください。"
            label="住所2（現住所：市町村区）"
            placeholder="例）大阪市"
            id="address2"
            v-bind:required="true"
          >
          </base-input>
          <base-input
            v-model="personalInfo.address3"
            description="現在お住まいの住所の市区町村以降を入力してください"
            label="住所3（現住所：市町村区以下）"
            placeholder="例）北区梅田1-1-1"
            id="address3"
            v-bind:required="true"
          >
          </base-input>
          <base-input
            v-model="personalInfo.phoneNumber"
            description="xxx-xxxx-xxxxの形式で入力してください。"
            label="連絡先電話番号"
            placeholder="例）080-1234-5678 ハイフンあり"
            id="phone-number"
            pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
            v-bind:required="true"
          >
          </base-input>
          <base-input
            v-model="personalInfo.email"
            label="メールアドレス"
            id="email"
            type="email"
            v-bind:required="true"
          >
          </base-input>
          <base-select
            v-model="personalInfo.receptionType"
            label="コイン受取方法"
            id="reception"
            value-field="code"
            text-field="name"
            v-bind:options="receptionTypeList"
            v-bind:required="true"
          >
          </base-select>
        </b-card-body>
      </b-card>
      <!-- 本人性確認書類 -->
      <b-card no-body class="mb-5 py-3">
        <b-card-body>
          <b-card-title title-tag="h3" class="text-cmain">
            本人性確認書類の提出
          </b-card-title>
          <b-card-text>
            以下に記載の書類を提出される方は、<b>1種類</b>の書類を提出していただきます。
          </b-card-text>
          <ul>
            <li>運転免許証</li>
            <li>運転経歴証（写真付きのもの）</li>
            <li>旅券（パスポート）</li>
            <li>在留カード</li>
            <li>特別永住者証明書</li>
            <li>外国人登録証明書</li>
            <li>個人番号カード（マイナンバーカード）</li>
          </ul>
          <b-card-text>
            以下に記載の書類を提出される方は、<b>2種類</b>の書類を提出していただきます。
          </b-card-text>
          <ul>
            <li>住民票</li>
            <li>運転経歴証（写真なしのもの）</li>
            <li>健康保険，国民健康保険又は船員保険等の被保険者証</li>
            <li>共済組合員証</li>
            <li>年金手帳</li>
            <li>国民年金，厚生年金保険又は船員保険にかかる年金証書</li>
            <li>共済年金又は恩給等の証書</li>
            <li>学生証</li>
          </ul>
          <b-card no-body class="mb-3">
            <b-card-body>
              <b-card-title title-tag="h4" class="text-cmain">
                提出する本人性確認書類の数
              </b-card-title>
              <b-form-group>
                <legend>
                  <b-badge variant="caccent">必須</b-badge>
                  提出する本人性確認書類の数を選択してください
                </legend>
                <b-form-radio
                  v-model="numberOfDocuments"
                  name="identification-number"
                  value="1"
                  class="mr-3 mb-2 mb-sm-0"
                  button
                  button-variant="outline-cmain"
                  >1種類の書類を提出</b-form-radio
                >
                <b-form-radio
                  v-model="numberOfDocuments"
                  name="identification-number"
                  value="2"
                  button
                  button-variant="outline-cmain"
                  >2種類の書類を提出</b-form-radio
                >
              </b-form-group>
              <image-uploader
                v-if="numberOfDocuments == 1 ? true : false"
                v-bind:file="identification.faceImage"
                text="顔写真"
                message="ご自身の顔写真をアップロードしてください"
                v-on:upload-file="identification.faceImage = $event.image"
              />
            </b-card-body>
          </b-card>
          <b-card no-body class="mb-3">
            <b-card-body>
              <b-card-title title-tag="h4" class="text-cmain">
                本人性確認書類記載の住所
              </b-card-title>
              <b-card-text>
                本人性確認書類記載の住所が現住所と異なる場合はチェックを入れて入力ください。
                <br />（本人性確認書類に住所の記載がない場合は入力不要です。）
              </b-card-text>
              <b-form-checkbox
                v-model="extraAddressRequired"
                class="box-large mb-3"
                name="extra-address"
              >
                <b>本人性確認書類の住所が現住所と異なる</b>
              </b-form-checkbox>
              <base-input
                v-model="personalInfo.extraAddress1"
                description="本人性確認書類（免許証など）記載の住所の都道府県を入力してください。"
                label="住所1（本人性確認書類記載の住所：都道府県）"
                placeholder="例）大阪府"
                id="extra-address1"
                v-bind:required="extraAddressRequired"
                v-bind:disabled="!extraAddressRequired"
              >
              </base-input>
              <base-input
                v-model="personalInfo.extraAddress2"
                description="本人性確認書類（免許証など）記載の住所の市区町村を入力してください。"
                label="住所2（本人性確認書類記載の住所：市町村区）"
                placeholder="例）大阪市"
                id="extra-address2"
                v-bind:required="extraAddressRequired"
                v-bind:disabled="!extraAddressRequired"
              >
              </base-input>
              <base-input
                v-model="personalInfo.extraAddress3"
                description="本人性確認書類（免許証など）記載の住所の市区町村以降を入力してください"
                label="住所3（本人性確認書類記載の住所：市町村区以下）"
                placeholder="例）北区梅田1-1-1"
                id="extra-address3"
                v-bind:required="extraAddressRequired"
                v-bind:disabled="!extraAddressRequired"
              >
              </base-input>
            </b-card-body>
          </b-card>
          <b-card no-body>
            <b-card-body>
              <b-card-title title-tag="h4" class="text-cmain">
                本人性確認書類のアップロード
              </b-card-title>
              <b-card-text>
                本人性確認書類の種類を選択し、[写真撮影・写真の選択]ボタンから撮影を行うか、撮影済みの写真の選択を行なってください。
              </b-card-text>
              <base-select
                v-model="identification.document1"
                label="本人性確認書類1"
                id="document1"
                value-field="code"
                text-field="name"
                v-bind:options="documentOptions1"
                v-bind:required="docment1Required"
                v-bind:disabled="!docment1Required"
                v-on:input="changeUploadArea(1)"
              >
              </base-select>
              <image-uploader
                v-bind:file="identification.file11"
                v-bind:file-name="identification.fileName11"
                v-bind:disabled="!documentUpload.required11"
                text="書類１表面"
                v-bind:message="documentUpload.message11"
                v-on:upload-file="
                  identification.file11 = $event.image
                  identification.fileName11 = $event.name
                "
              />
              <image-uploader
                v-bind:file="identification.file12"
                v-bind:file-name="identification.fileName12"
                v-bind:disabled="!documentUpload.required12"
                text="書類１裏面"
                v-bind:message="documentUpload.message12"
                v-on:upload-file="
                  identification.file12 = $event.image
                  identification.fileName12 = $event.name
                "
              />
              <base-select
                v-model="identification.document2"
                label="本人性確認書類2"
                id="document2"
                value-field="code"
                text-field="name"
                v-bind:options="documentOptions2"
                v-bind:required="docment2Required"
                v-bind:disabled="!docment2Required"
                v-on:input="changeUploadArea(2)"
              >
              </base-select>
              <image-uploader
                v-bind:file="identification.file21"
                v-bind:file-name="identification.fileName21"
                v-bind:disabled="!documentUpload.required21"
                text="書類２表面"
                v-bind:message="documentUpload.message21"
                v-on:upload-file="
                  identification.file21 = $event.image
                  identification.fileName21 = $event.name
                "
              />
              <image-uploader
                v-bind:file="identification.file22"
                v-bind:file-name="identification.fileName22"
                v-bind:disabled="!documentUpload.required22"
                text="書類２裏面"
                v-bind:message="documentUpload.message22"
                v-on:upload-file="
                  identification.file22 = $event.image
                  identification.fileName22 = $event.name
                "
              />
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-card>
      <b-form-row>
        <b-col offset="3" cols="6" offset-sm="4" sm="4">
          <b-button type="submit" variant="caccent" class="w-100">
            入力内容の確認
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
import ImageUploader from '@/components/common/ImageUploader'
import textGettable from '@/mixins/textGettable'
export default {
  components: {
    BaseInput,
    BaseSelect,
    ImageUploader,
  },
  mixins: [textGettable],
  props: ['storedData', 'documentsA', 'documentsB', 'receptionTypeList'],
  data() {
    return {
      personalInfo: {
        surname: null,
        firstname: null,
        surnameKana: null,
        firstnameKana: null,
        dateOfBirth: null,
        gender: null,
        genderText: null,
        address1: null,
        address2: null,
        address3: null,
        extraAddress1: null,
        extraAddress2: null,
        extraAddress3: null,
        phoneNumber: null,
        email: null,
        receptionType: null,
        receptionTypeName: null,
      },
      extraAddressRequired: false,
      numberOfDocuments: null,
      documentOptions1: [], //書類1の選択肢
      documentOptions2: [], //書類2の選択肢
      docment1Required: false, // 書類1select
      docment2Required: false, // 書類2select
      identification: {
        document1: null,
        documentName1: null,
        document2: null,
        documentName2: null,
        file11: null,
        fileName11: null,
        file12: null,
        fileName12: null,
        file21: null,
        fileName21: null,
        file22: null,
        fileName22: null,
        faceImage: null,
      },
      documentUpload: {
        required11: false, // 書類1表写真
        required12: false, // 書類1裏写真
        required21: false, // 書類2表写真
        required22: false, // 書類2裏写真
        message11: null,
        message12: null,
        message21: null,
        message22: null,
      },
    }
  },
  watch: {
    numberOfDocuments(newval, oldVal) {
      // 書類数watch
      console.log('* watch numberOfDocuments *', newval, oldVal)
      // 書類数を切り替えたときは書類データ初期化する
      if (oldVal) {
        for (let k in this.identification) {
          this.identification[k] = null
        }
        this.documentUpload.required11 = false
        this.documentUpload.required12 = false
        this.documentUpload.required21 = false
        this.documentUpload.required22 = false
        this.documentUpload.message11 = null
        this.documentUpload.message12 = null
        this.documentUpload.message21 = null
        this.documentUpload.message22 = null
      }
      // 書類数によって選択書類切り替え
      if (newval == 1) {
        this.documentOptions1 = this.documentsA
        this.documentOptions2 = []
        this.docment1Required = true
        this.docment2Required = false
      } else if (newval == 2) {
        this.documentOptions1 = this.documentsB
        this.documentOptions2 = this.documentsB
        this.docment1Required = true
        this.docment2Required = true
      } else {
        this.documentOptions1 = []
        this.documentOptions2 = []
        this.docment1Required = false
        this.docment2Required = false
      }
    },
    extraAddressRequired(val) {
      // 現住所chkを外したら入力されているものはクリアする
      if (!val) {
        this.personalInfo.extraAddress1 = null
        this.personalInfo.extraAddress2 = null
        this.personalInfo.extraAddress3 = null
      }
    },
  },
  created() {
    // 保持しているデータをセット
    Object.assign(this.personalInfo, this.storedData.personalInfo)
    this.extraAddressRequired = this.storedData.extraAddressRequired
    this.numberOfDocuments = this.storedData.numberOfDocuments
    Object.assign(this.identification, this.storedData.identification)
    Object.assign(this.documentUpload, this.storedData.documentUpload)
  },
  methods: {
    changeUploadArea(document) {
      // 選択した書類によってアップロードエリアの表示変更
      console.log('select document', document)
      let documentInfo = null
      if (document == 1 && this.identification.document1) {
        // 一つめの書類
        this.identification.file11 = null
        this.identification.fileName11 = null
        this.identification.file12 = null
        this.identification.fileName12 = null
        if (this.numberOfDocuments == 1) {
          // 書類1種類の場合
          documentInfo = this.documentsA.find(
            (v) => v.code == this.identification.document1
          )
        } else {
          // 書類2種類の場合
          documentInfo = this.documentsB.find(
            (v) => v.code == this.identification.document1
          )
        }
        this.documentUpload.message11 =
          documentInfo.name + 'の【表面】をアップロードしてください'
        this.documentUpload.required11 = true
        if (documentInfo.bothSides) {
          this.documentUpload.message12 =
            documentInfo.name + 'の【裏面】をアップロードしてください'
          this.documentUpload.required12 = true
        } else {
          this.documentUpload.message12 = '裏面は不要です'
          this.documentUpload.required12 = false
        }
      } else if (document == 2 && this.identification.document2) {
        // 二つめの書類
        this.identification.file21 = null
        this.identification.fileName21 = null
        this.identification.file22 = null
        this.identification.fileName22 = null
        documentInfo = this.documentsB.find(
          (v) => v.code == this.identification.document2
        )
        this.documentUpload.message21 =
          documentInfo.name + 'の【表面】をアップロードしてください'
        this.documentUpload.required21 = true
        if (documentInfo.bothSides) {
          this.documentUpload.message22 =
            documentInfo.name + 'の【裏面】をアップロードしてください'
          this.documentUpload.required22 = true
        } else {
          this.documentUpload.message22 = '裏面は不要です'
          this.documentUpload.required22 = false
        }
      }
    },
    toConfirm() {
      if (this.numberOfDocuments == 1 && !this.identification.faceImage) {
        alert('顔写真をアップロードしてください')
        return
      }
      if (this.documentUpload.required11 && !this.identification.file11) {
        alert(this.documentUpload.message11)
        return
      }
      if (this.documentUpload.required12 && !this.identification.file12) {
        alert(this.documentUpload.message12)
        return
      }
      if (this.documentUpload.required21 && !this.identification.file21) {
        alert(this.documentUpload.message21)
        return
      }
      if (this.documentUpload.required22 && !this.identification.file22) {
        alert(this.documentUpload.message22)
        return
      }
      if (this.identification.document1 == this.identification.document2) {
        alert('本人性確認書類1と2に同じ書類が選択されています。')
        return
      }
      // データを親に渡して保持する
      if (this.numberOfDocuments == 1) {
        this.identification.documentName1 = this.documentsA.find(
          (v) => v.code == this.identification.document1
        ).name
      }
      if (this.numberOfDocuments == 2) {
        this.identification.documentName1 = this.documentsB.find(
          (v) => v.code == this.identification.document1
        ).name
        this.identification.documentName2 = this.documentsB.find(
          (v) => v.code == this.identification.document2
        ).name
      }
      this.personalInfo.receptionTypeName = this.receptionTypeList.find(
        (v) => v.code == this.personalInfo.receptionType
      ).name
      this.personalInfo.genderText = this.getText(
        'gender',
        this.personalInfo.gender
      )

      this.$emit(
        'to-confirm',
        this.personalInfo,
        this.extraAddressRequired,
        this.numberOfDocuments,
        this.identification,
        this.documentUpload
      )
      // 確認ページへ
      this.$router.push('/account-opening/application-form/confirm')
    },
  },
}
</script>
